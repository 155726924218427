@import "./_mixins";

@font-face {
  font-family: "StarGirls";
  src: url("./Stargirl.otf") format("opentype");
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

#root {
  max-width: 100%;
}

body {
  margin: 0;
  background-image: url(./images/background.png);
  height: 100%;
  width: 100%;
  font-family: Nunito, Helvetica, Arial, sans-serif;
}

section {
  margin: 10px;
  padding: 5px;

  @media (width >= $md) {
    margin: 40px;
    padding: 20px;
  }
}

p,
li {
  font-size: 15pt;
}

@font-palette-values --myPalette {
  font-family: "Honk";
  base-palette: 5;
  override-colors:
    0 black,
    1 black,
    2 #f9868f,
    3 #f9868f,
    4 #f9868f,
    5 white,
    6 #f9bd54,
    7 #f9bd54,
    8 #f9bd54,
    9 #f9bd54,
    10 #f9bd54;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  letter-spacing: 3px;
  font-palette: --myPalette;
}

@supports font-format(woff2) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Stargirl, Poppins, Honk, Helvetica, Arial, sans-serif;
  }
}

h1 {
  font-size: 3em;

  @media (width >= $lg) {
    font-size: 3.8em;
  }

  @media (width >= $xl) {
    font-size: 5em;
  }
}

h2 {
  font-size: 2em;

  @media (width >= $lg) {
    font-size: 3.5em;
  }

  @media (width >= $xl) {
    font-size: 3.8em;
  }
}
