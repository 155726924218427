@import "../_mixins";

.projects-container {
  background-image: url("../images/gingham_plaid_background2.png");
  background-size: 350px;
  margin-top: 0;
  margin-bottom: 0;

  h2 {
    padding-left: 20px;
  }

  .projects {
    background-color: rgba(255, 255, 255, 0.4);
    //border: 2px solid black;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (width < $md) {
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }
  }
}
