@import "../_mixins";

.intro-box {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  z-index: 0;
  flex-direction: column;
  margin-top: 100px;

  @media (width < 490px) {
    margin-top: 80px;
  }

  @media (width >= $lg) {
    flex-direction: row;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // TODO:
    // add nice background :)
    background: url("../images/poke.png");
    background-color: white;
    opacity: 0.5;
    z-index: -1;
  }

  .intro-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    //border: 4px solid black;

    @media (width >= $lg) {
      width: 60%;
      margin: 0;
    }

    .intro-img {
      max-width: 500px;
      min-width: 250px;
      width: 100%;
      border-radius: 50px;

      @media (width < $lg) {
        width: 50%;
      }
    }
  }

  .intro-content {
    //border: 4px solid red;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (width >= $lg) {
      margin-left: 10px;
      width: 100%;
      padding: 20px;
    }

    .intro-text {
      background-color: white;
      padding: 10px;

      @media (width >= $lg) {
        width: 90%;
      }

      .stars {
        width: 100%;
      }
    }

    h3 {
      background: white;
      font-size: 20pt;
      padding: 10px;
      display: block;
      margin-bottom: 10px;
      width: fit-content;

      .wink {
        margin-bottom: -2px;
      }
    }

    .affiliate-banners {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (width >= $lg) {
        flex-direction: row;
        justify-content: space-between;
      }

      .affiliate-banner {
        padding: 0 10px;
        width: 80%;
        margin-bottom: 15px;
        max-width: 350px;
        min-width: 88px;
        min-height: 31px;

        img {
          width: 100%;
        }

        @media (width >= $lg) {
          width: 200%;
          margin-bottom: 0;
        }
      }
    }
  }
}
