@import "../_mixins";

.header {
  margin: 10px;
  display: flex;
  width: 100%;
  padding-top: 20px;

  > .wanderlust {
    top: 5px;
    left: 70px;

    @media (width >= $md) {
      left: 170px;
      top: 30px;
    }
  }

  .content-container {
    display: flex;
    padding-right: 50px;
    width: 100%;

    @media (width < 570px) {
      padding-right: 20px;
    }

    .pic-container {
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      min-width: 100px;
      height: 100px;

      @media (width >= $md) {
        min-width: 200px;
        height: 110%;
      }

      img {
        max-width: 200px;
        width: 90px;

        border-radius: 50%;

        @media (width >= $md) {
          width: 90%;
        }
      }
    }

    .title-container {
      background-color: white;
      z-index: 0;
      width: 100%;
      height: 63px;
      margin-top: 20px;
      margin-left: -90px;

      @media (width < 570px) {
        margin-top: 20px;
        margin-left: -60px;
      }

      @media (width >= $md) {
        height: 70px;
        margin-top: 40px;
        margin-left: -100px;
      }

      h1 {
        margin-left: 130px;
        margin-top: 0px;
        letter-spacing: 3px;

        @media (width < $md) {
          font-size: 30pt;
        }

        @media (width < 570px) {
          font-size: 24pt;
          margin-left: 60px;
          letter-spacing: 2px;
        }

        @media (width < 480px) {
          font-size: 19pt;
          letter-spacing: 1px;
        }

        @media (width < 390px) {
          font-size: 16pt;
          letter-spacing: 1px;
        }

        @media (width >= $lg) {
          letter-spacing: 10px;
          margin-top: -21px;
        }
      }

      h2 {
        background: white;
        border-radius: 50px;
        letter-spacing: 3px;
        margin-left: 80px;
        padding: 5px;
        padding-left: 32px;
        margin-top: 50px;

        @media (width < 570px) {
          margin-left: 70px;
          font-size: 18pt;
          padding-left: 18px;
        }

        @media (width < 480px) {
          margin-left: 0;
          font-size: 18pt;
          padding-left: 18px;
        }

        @media (width < 397px) {
          font-size: 17pt;
          letter-spacing: 1px;
          margin-top: 70px;
        }

        @media (width < 327px) {
          font-size: 16pt;
          letter-spacing: 1px;
          margin-top: 20px;
        }

        @media (width >= $lg) {
          width: 70%;
          margin-left: 130px;
          padding: 20px;
          padding-left: 54px;
          font-size: 2.5em;
        }

        @media (width >= $xl) {
          margin-top: -10px;
        }
      }
    }
  }
}
