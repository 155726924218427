.about {
  background-color: #fff8f8;
  padding: 20px;

  .keyboard {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    min-width: 300px;
  }

  .about-container {
    h3 {
      font-family: Poppins, Helvetica, sans-serif;
      font-size: 20pt;
      margin-top: 60px;
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
