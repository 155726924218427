@import "../_mixins";

.project {
  padding: 20px 0;
  //border: 2px solid red;
  display: flex;
  max-width: 450px;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  z-index: 1;

  @media (width >= 975px) {
    width: 45%;
  }

  .project-top {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (width < $sm) {
      flex-direction: column;
      justify-content: left;
    }

    .intro-img {
      //width: 200px;
      width: 200px;
      width: 50%;
      height: 200px;
      border-radius: 25px;
      //border: 2px solid blue;
      background-color: white;

      @media (width < $sm) {
        width: auto;
        align-self: center;
        margin-bottom: 20px;
      }
    }

    .project-details {
      width: 50%;
      padding: 0 20px;

      @media (width < $sm) {
        width: 100%;
        padding-left: 0;
        margin-bottom: 20px;
      }

      h3 {
        font-family: Poppins, Helvetica, sans-serif;
        margin-top: 0;
        font-size: 20pt;
        margin-bottom: 10px;
      }

      .project-link {
        display: flex;
        color: black;
        align-items: center;
        background-color: #fee6e3;
        border: 2px solid #111;
        border-radius: 8px;
        box-sizing: border-box;
        font-family: Inter, sans-serif;
        font-size: 16px;
        height: 48px;
        justify-content: center;
        line-height: 24px;
        max-width: 100%;
        padding: 0 25px;
        position: relative;
        text-align: center;
        text-decoration: none;
        margin-bottom: 15px;

        &:after {
          background-color: #111;
          border-radius: 8px;
          content: "";
          display: block;
          height: 48px;
          left: 0;
          width: 100%;
          position: absolute;
          top: -2px;
          transform: translate(8px, 8px);
          transition: transform 0.2s ease-out;
          z-index: -1;
        }

        &:active {
          background-color: #ffdeda;
          outline: 0;
        }

        &:hover {
          outline: 0;
          &:after {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .project-description {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    background-color: white;
    background-color: rgba(251, 215, 199, 0.7);

    p {
      padding: 10px;
    }
  }
}
